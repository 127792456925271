<div>
  <div class="flex align-items-center py-0 px-3 w-full z-2 px-5" style="height: 62px; box-shadow: 0 10px 40px 0 rgb(41 50 65 / 6%); background: var(--surface-card)">
    <a id="logolink" [routerLink]="['/']">

    </a>
  </div>
  <div class="flex flex-column justify-content-center align-items-center" style="min-height: calc(100vh - 62px)">
    <div class="flex flex-column justify-content-center">
      <img src="assets/layout/images/pages/asset-access.svg" alt="freya-layout" style="width: 332px; height: 271px" />
      <span class="block text-7xl text-center">access denied</span>
    </div>
  </div>
</div>

import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(private oidcSecurityService: OidcSecurityService, @Inject('SECURE_ROUTES') private securedRoutes: string[]) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.securedRoutes.find(x => request.url.startsWith(x))) {
      return next.handle(request);
    }
    return this.oidcSecurityService.getAccessToken().pipe(
      switchMap(token => {
        request = request.clone({
          headers: request.headers.set('Authorization', 'Bearer ' + token),
        });
        return next.handle(request);
      })
    );
  }
}

import { Route } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { MatchComponent } from './match/match.component';
import { AdminGuard, UserGuard } from '@frontend/auth/auth/guards/admin.guard';
import { authRoutes } from '@frontend/auth/auth/auth.routes';
import { MatchSetupComponent } from './match-setup/match-setup.component';

export const appRoutes: Route[] = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  ...authRoutes,
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'match',
    component: MatchComponent,
    canActivate: [UserGuard],
  },
  {
    path: 'match-setup',
    component: MatchSetupComponent,
    canActivate: [UserGuard],
  }

];

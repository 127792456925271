import { Route } from '@angular/router';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { CallbackComponent } from './callback/callback.component';
import {AutoLoginPartialRoutesGuard} from "angular-auth-oidc-client";
import {ProtectedComponent} from "./protected/protected.component";
import {ForbiddenComponent} from "./forbidden/forbidden.component";


export const authRoutes: Route[] = [
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'callback', component: CallbackComponent },
  {
    path: 'protected',
    component: ProtectedComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'forbidden',
    component: ForbiddenComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
];

import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OidcSecurityService, UserDataResult } from 'angular-auth-oidc-client';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'frontend-home',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css',
})
export class HomeComponent implements OnInit {
  userData: UserDataResult | null =null;

  constructor(public oidcSecurityService: OidcSecurityService) {}

  ngOnInit() {
    // this.oidcSecurityService.getUserData().subscribe(p=>{
    //   this.userData = p;
    // });
    // this.oidcSecurityService.getAuthenticationResult().subscribe(p => {
    //   console.log('getAuthenticationResult:', p);
    // });
    // this.oidcSecurityService.getPayloadFromIdToken().subscribe(p => {
    //
    //   console.log('getPayloadFromIdToken:', p);
    // });
    // this.oidcSecurityService.checkAuth().subscribe(
    //   ({ isAuthenticated }) => {
    //     console.warn('authenticated: ', isAuthenticated);
    //   }
    // );

  }
}

import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'frontend-match-setup',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './match-setup.component.html',
  styleUrl: './match-setup.component.css',
})
export class MatchSetupComponent {
  sportTypes: { value:string, viewValue:string }[]= [{value:'squash',viewValue:'squash'}];
}

import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Router } from '@angular/router';

@Component({
  selector: 'frontend-callback',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './callback.component.html',
  styleUrl: './callback.component.css'
})
export class CallbackComponent implements OnInit {
  constructor(private oidcSecurityService: OidcSecurityService, private router: Router) {
  }

  ngOnInit(): void {
    this.oidcSecurityService.checkAuth().subscribe(p => {
      this.router.navigate(['/home']);
    });
  }

}

import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { inject } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';

export const UserGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {

  const router: Router = inject(Router);
  const oidcSecurityService: OidcSecurityService = inject(OidcSecurityService);

  return oidcSecurityService.getPayloadFromIdToken()
    .pipe(
      switchMap(p => {
        if (p.hasOwnProperty('realm_access') && p['realm_access'].hasOwnProperty('roles')) {
          let roles =  p['realm_access']['roles'] as string[];
          return roles.includes('user') ? of(true) :  router.navigate(['forbidden']);;
        }
        return router.navigate(['forbidden']);
      })
    );
}

import {LogLevel} from "angular-auth-oidc-client";

const API_URL:string = 'https://localhost:7185/';
export const environment = {
  production: false,
  apiUrl: API_URL,
  auth: {
    debug: true,
    keycloak: {
      postLoginRoute: 'home',
      forbiddenRoute: '/forbidden',
      unauthorizedRoute: '/unauthorized',
      logLevel: LogLevel.Debug,
      historyCleanupOff: false,
      authority: 'https://dev.identity.tourandmatch.com/realms/TourAndMatch',
      redirectUrl: `${window.location.origin}/callback`,
      postLogoutRedirectUri: window.location.origin,
      clientId: 'tourandmatchclient',
      scope: 'email profile openid offline_access',
      responseType: 'code',
      silentRenew: true,
      secureRoutes: [API_URL],
      useRefreshToken: true,
      ignoreNonceAfterRefresh: true
    },
  },
};

import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { appRoutes } from './app.routes';
import { AuthInterceptor, provideAuth } from 'angular-auth-oidc-client';
import { environment } from '../environments/environment';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

function authProvisder() {
  return undefined;
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(appRoutes),
    provideAuth({
      config: {
        configId: 'keycloak',
        postLoginRoute: environment.auth.keycloak.postLoginRoute,
        forbiddenRoute: environment.auth.keycloak.forbiddenRoute,
        unauthorizedRoute: environment.auth.keycloak.unauthorizedRoute,
        logLevel: environment.auth.keycloak.logLevel,
        historyCleanupOff: environment.auth.keycloak.historyCleanupOff,
        authority: environment.auth.keycloak.authority,
        redirectUrl: environment.auth.keycloak.redirectUrl,
        postLogoutRedirectUri: environment.auth.keycloak.postLogoutRedirectUri,
        clientId: environment.auth.keycloak.clientId,
        scope: environment.auth.keycloak.scope,
        responseType: environment.auth.keycloak.responseType,
        silentRenew: environment.auth.keycloak.silentRenew,
        secureRoutes: environment.auth.keycloak.secureRoutes,
        useRefreshToken: environment.auth.keycloak.useRefreshToken,
        ignoreNonceAfterRefresh: environment.auth.keycloak.ignoreNonceAfterRefresh,
        renewTimeBeforeTokenExpiresInSeconds: 10,
      },
    }),
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {provide: 'API_URL', useValue: environment.apiUrl}, provideAnimationsAsync(),
  ],
};
